import { Popover } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import StateStatus from '../../../utils/status';
import { WorkspaceService } from '../../../features/private/Campaign/service';
import Select from '../Select';
import Button from '../Button';
import feedback from '../../components/Feedback';

import './styles.scss';

export const AddToWorkspaceModal = ({
  influencerId,
  children,
  onClick,
}) => {
  const { t } = useTranslation();

  const [workspaces, setWorkspaces] = useState([]);
  const [columns, setColumns] = useState([]);
  const [status, setStatus] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [query, setQuery] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (showModal) {
      fetchWorkspaces(query);
    }
  }, [query, showModal]);

  useEffect(() => {
    if (!showModal) {
      clearTimeout(timeoutId);
      setWorkspaces([]);
      setColumns([]);
      setStatus({});
    }
  }, [showModal]);

  const fetchWorkspaces = async (query) => {
    setStatus({ ...status, fetchWorkspaces: StateStatus.loading });
    try {
      const response = await WorkspaceService.campaigns.getAll(0, query);
      const formattedData = response.data.items.map((item) => ({
        key: item.title,
        value: item.uid,
        columns: item.columns.map((col) => ({ key: col.title, value: col.uid })),
      }));
      setWorkspaces(formattedData);
      setStatus({ ...status, fetchWorkspaces: StateStatus.succeeded });
    } catch (error) {
      setStatus({ ...status, fetchWorkspaces: StateStatus.failed });
    }
  };

  const handleWorkspaceChange = (selectedValue) => {
    setSelectedWorkspace(selectedValue);
    const selectedWorkspaceData = workspaces.find((w) => w.value === selectedValue);
    if (selectedWorkspaceData) {
      setColumns(selectedWorkspaceData.columns);
    } else {
      setColumns([]);
    }
    setSelectedColumn(null);
  };

  const handleColumnChange = (selectedValue) => {
    setSelectedColumn(selectedValue);
  };


  const handleAddInfluencer = async () => {
    if (!selectedWorkspace || !selectedColumn) return;

    setStatus({ ...status, addInfluencer: StateStatus.loading });
    try {
      await WorkspaceService.profiles.add(selectedWorkspace, {
        column_uid: selectedColumn,
        profile_id: influencerId
      });
      setStatus({ ...status, addInfluencer: StateStatus.succeeded });
      setShowModal(false);
      feedback.success('Influencer adicionado com sucesso à área de trabalho.');
    } catch (error) {
      setStatus({ ...status, addInfluencer: StateStatus.failed });
    }
  }

  return (
    <div className="container-modal-lists">
      <Popover
        trigger="click"
        open={showModal}
        onOpenChange={() => {
          setShowModal(!showModal);

          onClick?.();
        }}
        content={() => (
          <div
            className={`modal-lists ${
              showModal ? 'open-modal-lists-add-to-workspace' : 'close-modal-lists'
            }`}
          >
            <div className="modal-lists-header">
              <span className="modal-lists-header-title">{t('Áreas de Trabalho')}</span>
              <i className="icon icon-close" onClick={() => setShowModal(false)} />
            </div>

            <div className="modal-lists-content-add-to-workspace">
              <label>Pesquise ou selecione uma Área de Trabalho:</label>
              <Select
                placeholder={t('Selecione a Área de Trabalho')}
                onChange={handleWorkspaceChange}
                value={selectedWorkspace}
                loading={status.fetchWorkspaces === StateStatus.loading}
                options={workspaces}
                showSearch
                onSearch={(value) => {
                  clearTimeout(timeoutId);
                  setTimeoutId(setTimeout(() => setQuery(value), 300));
                }}
              />

              <br />
              <br />
              {columns.length > 0 && (
                <>
                  <label>{t('Selecione uma Coluna')}:</label>

                  <Select
                    placeholder={t('Selecione a coluna')}
                    onChange={handleColumnChange}
                    value={selectedColumn}
                    options={columns}
                    disabled={!selectedWorkspace}
                  />
                  <br />
                  <br />

                 <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                 <Button onClick={handleAddInfluencer} isDisabled={!selectedColumn || status.fetchWorkspaces === StateStatus.loading}>
                 Incluir</Button>
                 </div>
                </>
              )}

              {status.fetchWorkspaces === StateStatus.failed && (
                <p className="text-error">{t('Erro ao carregar as áreas de trabalho')}</p>
              )}
            </div>
          </div>
        )}
      >
        {children}
      </Popover>
    </div>
  );
};

AddToWorkspaceModal.propTypes = {
  influencerId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

AddToWorkspaceModal.defaultProps = {
  removeInfluencer: null,
  onClick: null,
};
