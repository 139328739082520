import React from "react"
import PropTypes from "prop-types"
// 
import { Select as AntSelect} from "antd"
// 
import "./index.scss"

const { Option } = AntSelect

const Select = ({
    onChange,
    allowClear,
    options,
    isDisabled,
    placeholder,
    defaultValue,
    showSearch,
    ...props
}) => ( 
     <AntSelect
        allowClear={allowClear}
        className="select" 
        disabled={isDisabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        clearIcon={<i className="icon icon-close" />}
        suffixIcon={(allowClear && props.value) ? <i className="icon icon-close" /> : <i className="icon icon-down" />}
        onChange={onChange}
        showSearch={showSearch}
        filterOption={false}
        {...props}
    >
        {options && options.map((option, key) => {
            return <Option 
                key={key} 
                value={option["value"]}
                disabled={option["disabled"]}
            >
                {option["icon"] && option["icon"]}
                
                {option["key"]}
            </Option>
        })}
    </AntSelect>
)

Select.defaultValue = {
    isDisabled: false,
    allowClear: false,
    onChange: () => {},
    options: PropTypes.arrayOf(PropTypes.object),
    showSearch: false
}

Select.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    showSearch: PropTypes.bool
}

export default Select